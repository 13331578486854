import { useApolloClient } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { REGEXP_ONLY_DIGITS_AND_CHARS } from "input-otp"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"
import * as z from "zod"
import { gql } from "~/__generated__"
import { useViewerMaybe } from "~/auth/use-viewer"
import { createApolloLink } from "~/common/create-apollo-link"
import { adminDashboardPath, adminLoginPath } from "~/common/paths"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { TextField } from "~/fields/text-field"
import logoLg from "~/images/logo-lg"
import mailIcon from "~/images/mail-icon"
import { AppText } from "~/ui/app-text"
import { Button } from "~/ui/button"
import { Form, FormControl, FormField, FormItem, FormMessage } from "~/ui/form"
import { InputOTP, InputOTPGroup, InputOTPSlot } from "~/ui/input-otp"
import { useToast } from "~/ui/use-toast"

const emailLoginFormSchema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
})

const otpFormSchema = z.object({
  otp: z.string().length(6, {
    message: "Your one-time password must be 6 characters.",
  }),
})

type EmailLoginFormValues = z.infer<typeof emailLoginFormSchema>
type OtpFormValues = z.infer<typeof otpFormSchema>

const EMAIL_AUTH_CHALLENGE_MUTATION = gql(/* GraphQL */ `
  mutation EmailAuthChallenge($input: EmailUserAuthChallengeInput!) {
    emailUserAuthChallenge(input: $input) {
      success
    }
  }
`)

const EMAIL_TOKEN_AUTH_MUTATION = gql(/* GraphQL */ `
  mutation EmailTokenAuth($input: EmailTokenUserAuthInput!) {
    emailTokenUserAuth(input: $input) {
      success
      csrfToken
    }
  }
`)

const OtpForm = ({ email }: { email: string }) => {
  const { toast } = useToast()
  const navigate = useNavigate()
  const {
    result: { refetch: viewerRefetch },
  } = useViewerMaybe()
  const [emailTokenAuth, { loading: tokenAuthLoading }] = useSafeMutation(EMAIL_TOKEN_AUTH_MUTATION)
  const apolloClient = useApolloClient()
  const otpForm = useForm<OtpFormValues>({
    resolver: zodResolver(otpFormSchema),
    defaultValues: {
      otp: "",
    },
  })

  const onOtpSubmit = async (values: OtpFormValues) => {
    const result = await emailTokenAuth({
      variables: {
        input: {
          email,
          token: values.otp,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
    })

    if (result.errors) {
      console.error("Email token auth failed:", result.errors)
      toast({
        title: "Authentication Failed",
        description: "An error occurred during authentication. Please try again.",
        variant: "destructive",
      })
      return
    }

    if (result.data?.emailTokenUserAuth.success) {
      apolloClient.link = createApolloLink(result.data.emailTokenUserAuth.csrfToken)
      viewerRefetch()
      toast({
        title: "Authentication Successful",
        description: "You have been successfully logged in.",
        variant: "default",
      })
      navigate(adminDashboardPath({}))
    } else {
      toast({
        title: "Authentication Failed",
        description: "Unable to authenticate. Please try again.",
        variant: "destructive",
      })
    }
  }

  return (
    <Form {...otpForm}>
      <form
        onSubmit={otpForm.handleSubmit(onOtpSubmit)}
        className="flex flex-col items-center space-y-4"
      >
        <AppText variant="h1" className="text-center">
          Enter code
        </AppText>
        <FormField
          control={otpForm.control}
          name="otp"
          render={({ field }) => (
            <FormItem className="flex flex-col items-center">
              <FormControl>
                <InputOTP
                  maxLength={6}
                  {...field}
                  pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
                  data-1p-ignore="true"
                >
                  <InputOTPGroup>
                    <InputOTPSlot index={0} autoFocus={true} />
                    <InputOTPSlot index={1} />
                    <InputOTPSlot index={2} />
                    <InputOTPSlot index={3} />
                    <InputOTPSlot index={4} />
                    <InputOTPSlot index={5} />
                  </InputOTPGroup>
                </InputOTP>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" className="w-full" disabled={tokenAuthLoading}>
          Continue with login code
        </Button>
      </form>
    </Form>
  )
}

export const EmailLoginScreen: React.FC = () => {
  const [emailAuthChallenge, { loading: challengeLoading }] = useSafeMutation(
    EMAIL_AUTH_CHALLENGE_MUTATION
  )
  const { toast } = useToast()
  const [step, setStep] = useState<"email" | "checkEmail" | "otp">("email")
  const [email, setEmail] = useState("")

  const emailForm = useForm<EmailLoginFormValues>({
    resolver: zodResolver(emailLoginFormSchema),
    defaultValues: {
      email: "",
    },
  })

  const onEmailSubmit = async (values: EmailLoginFormValues) => {
    const storedAuthCodes = JSON.parse(localStorage.getItem("authCodes") || "[]")
    let newClientAuthCode: string
    if (storedAuthCodes.length === 0) {
      newClientAuthCode = uuidv4()
      localStorage.setItem("authCodes", JSON.stringify([newClientAuthCode]))
    } else {
      newClientAuthCode = storedAuthCodes[storedAuthCodes.length - 1]
    }

    const result = await emailAuthChallenge({
      variables: {
        input: {
          email: values.email,
          clientAuthCode: newClientAuthCode,
        },
      },
    })

    if (result.errors) {
      console.error("Email auth challenge failed:", result.errors)
      toast({
        title: "Login Failed",
        description: "An error occurred during login. Please try again.",
        variant: "destructive",
      })
      return
    }

    if (result.data?.emailUserAuthChallenge.success) {
      setEmail(values.email)
      setStep("checkEmail")
      toast({
        title: "Email Sent",
        description: "Please check your email for the login link.",
        variant: "default",
      })
    } else {
      toast({
        title: "Login Failed",
        description: "Unable to send login email. Please try again.",
        variant: "destructive",
      })
    }
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="min-h-[430px] w-full max-w-md space-y-8">
        <Link to={adminLoginPath({})} className="flex" reloadDocument={true}>
          <img {...logoLg} alt="ChatBCC" />
        </Link>

        {step === "email" ? (
          <Form {...emailForm}>
            <div className="space-y-2">
              <AppText variant="h1" className="text-center">
                ChatBCC Admin
              </AppText>
              <AppText variant="body2" className="max-w-[325px] text-center">
                Provide your email and we will send you a magic link to sign in.
              </AppText>
            </div>
            <form
              onSubmit={emailForm.handleSubmit(onEmailSubmit)}
              className="flex flex-col items-center space-y-4"
            >
              <TextField
                control={emailForm.control}
                name="email"
                type="email"
                placeholder="Enter your email"
                icon={<img {...mailIcon} />}
                autoFocus={true}
              />
              <Button type="submit" disabled={challengeLoading}>
                {challengeLoading ? "Sending..." : "Get Magic Link"}
              </Button>
            </form>
          </Form>
        ) : step === "checkEmail" ? (
          <div className="flex flex-col items-center">
            <div className="flex flex-col justify-center space-y-2">
              <AppText variant="h1" className="text-center">
                Check Your Email
              </AppText>
              <AppText variant="body2" className="max-w-[325px] text-center">
                We have sent reset instructions to your email.
              </AppText>
            </div>
            <Button onClick={() => setStep("otp")} className="mt-6" variant="ghost">
              Enter code manually
            </Button>
          </div>
        ) : step === "otp" ? (
          <OtpForm email={email} />
        ) : null}
      </div>
    </div>
  )
}
