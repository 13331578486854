import { Outlet } from "react-router-dom"
import { getMetaContent } from "~/common/get-meta-content"
import { AppText } from "~/ui/app-text"

export const LoginLayout = () => {
  return (
    <div className="flex min-h-full flex-1 flex-col items-center justify-between bg-base-blue">
      <div />
      <div className="mx-auto max-w-[353px]">
        <div className="grid gap-8 py-8">
          <Outlet />
        </div>
      </div>

      <AppText variant="caption" className="py-4 text-center text-misc-base-black-70">
        © {new Date().getFullYear()} {getMetaContent("APP_NAME")} Copyright. All Rights Reserved
      </AppText>
    </div>
  )
}
