import { Link } from "react-router-dom"
import backArrow from "~/images/back-arrow"
import { AppText } from "~/ui/app-text"
import { Button } from "./button"

export const PageHeader = ({ title, backPath }: { title: string; backPath?: string }) => {
  return (
    <div className="mb-6">
      {!!backPath && (
        <Button asChild={true} variant="ghost" size="xs" className="mb-10 px-0 uppercase">
          <Link to={backPath} className="flex gap-1.5">
            <img {...backArrow} />
            Back
          </Link>
        </Button>
      )}
      <AppText variant="h1">{title}</AppText>
    </div>
  )
}
