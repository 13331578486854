import { InputHTMLAttributes } from "react"
import { Control, FieldPath, FieldValues } from "react-hook-form"
import { cn } from "~/common/cn"
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "~/ui/form"
import { Input } from "../ui/input"

export const TextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  label,
  labelClassName,
  autoFocus,
  type = "text",
  icon,
  placeholder,
}: {
  label?: string
  labelClassName?: string
  control: Control<TFieldValues> | undefined
  name: TName
  autoFocus?: boolean
  type?: string
  icon?: React.ReactNode
  placeholder?: string
} & InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          {!!label && <FormLabel className={cn(labelClassName)}>{label}</FormLabel>}
          <FormControl>
            <Input
              {...field}
              type={type}
              placeholder={placeholder}
              icon={icon}
              autoFocus={autoFocus}
            />
          </FormControl>
          <FormMessage className="text-xs" />
        </FormItem>
      )}
    />
  )
}
