import * as React from "react"

import { cn } from "~/common/cn"

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ReactNode
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, icon, ...props }, ref) => {
    return (
      <div className="relative flex items-center">
        {icon && <div className="absolute left-5 z-10">{icon}</div>}
        <input
          type={type}
          className={cn(
            "text-sm flex h-12 w-full rounded-full border border-base-black bg-background px-5 py-2 ring-offset-background",
            "file:text-sm file:border-0 file:bg-transparent file:font-medium",
            "placeholder:text-muted-foreground",
            "focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-0",
            "disabled:cursor-not-allowed disabled:opacity-50",
            className,
            { "pl-12": !!icon }
          )}
          ref={ref}
          {...props}
        />
      </div>
    )
  }
)
Input.displayName = "Input"

export { Input }
