// type safe paths
// https://github.com/garybernhardt/static-path
//
// tip: unless you're adding the route to the router, always call these as a
// function even if they don't take parameters
// e.g.  <Link to={rootPath({})}> and not <Link to={rootPath.pattern}>

import { path } from "static-path"

export const rootPath = path("/")
export const adminLoginPath = path("/admin/login")
export const adminCredentialsLoginPath = path("/admin/login/credentials")
export const adminDashboardPath = path("/admin")
export const adminAdminUsersPath = path("/admin/admin-users")
export const adminNewAdminUserPath = path("/admin/admin-users/new")
export const adminUsersPath = path("/admin/users")
export const adminChatsPath = path("/admin/chats")
export const detailsPath = path("/details")
export const profilePath = path("/profile")

export const emailAuthPath = path("/auth/email/:email/:token/:clientAuthCode")
