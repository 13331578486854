import { gql } from "~/__generated__"
import { UserRole } from "~/__generated__/graphql"
import { Button } from "~/ui/button"
import { useToast } from "~/ui/use-toast"
import { useSafeMutation } from "./use-safe-mutation"

const ADMIN_USER_UPDATE_MUTATION = gql(/* GraphQL */ `
  mutation AdminUserUpdate($input: AdminUserUpdateInput!) {
    adminUserUpdate(input: $input) {
      user {
        ...UserOverview
      }
    }
  }
`)

export const ArchiveButton = ({ id }: { id: string }) => {
  const { toast } = useToast()
  const [mutate, result] = useSafeMutation(ADMIN_USER_UPDATE_MUTATION, {
    update(cache) {
      cache.evict({ id: `User:${id}` })
      cache.gc()
    },
  })

  const archive = async () => {
    const confirmed = window.confirm("Are you sure?")
    if (!confirmed) {
      return
    }

    const result = await mutate({
      variables: {
        input: {
          id,
          userInput: {
            userRole: UserRole.Default,
          },
        },
      },
    })

    if (result.errors) {
      toast({
        title: "Archiving admin failed",
        description: "Please try again",
        variant: "destructive",
      })
      return
    }

    toast({
      title: "Admin archived",
      variant: "default",
    })
  }

  return (
    <Button variant="outline" size="sm" onClick={archive} disabled={result.loading}>
      Archive
    </Button>
  )
}
