const imgixResize = (
  imageUrl: string,
  {
    width,
    height,
    fit,
  }: {
    width?: number
    height?: number
    fit?: string
  }
) => {
  if (!imageUrl.includes("imgix")) return imageUrl

  const url = new URL(imageUrl)

  if (width) {
    url.searchParams.set("width", width.toString())
  }

  if (height) {
    url.searchParams.set("height", height.toString())
  }

  if (fit) {
    url.searchParams.set("fit", fit)
  }

  return url.toString()
}

export default imgixResize
