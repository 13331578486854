import { Link } from "react-router-dom"
import { UserRole } from "~/__generated__/graphql"
import { useViewerMaybe } from "~/auth/use-viewer"
import { adminDashboardPath } from "~/common/paths"
import { Button } from "~/ui/button"

export const RootScreen = () => {
  const { viewer } = useViewerMaybe()
  return (
    <div data-testid="root-screen" className="">
      {viewer?.userRole === UserRole.SystemAdmin && (
        <Button asChild={true} variant="ghost">
          <Link to={adminDashboardPath({})}>Admin Dashboard</Link>
        </Button>
      )}
    </div>
  )
}
